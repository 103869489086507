<template>
  <div class="warp">
    <div class="main">
      <h3>订单信息</h3>
      <van-field label="订单编号" label-width="90" :value="itemList.orderNo" disabled />
      <van-field label="下单时间" label-width="90" :value="conversionTime(itemList.createTime)" disabled />
      <van-field v-if="itemList.appointmentEndTime" label="预约时间" label-width="90" :value="itemList.appointmentBeginTime + '-' + itemList.appointmentEndTime.substring(11)" disabled />
      <h3>收运信息</h3>
      <van-field v-if="itemList.appointmentEndTime" label="预约时间" label-width="90" :value="itemList.appointmentBeginTime + '-' + itemList.appointmentEndTime.substring(11)" disabled />
      <van-field label="产废企业" label-width="90" :value="itemList.companyName" disabled />
      <van-field label="清运地址" label-width="90" type="textarea" rows="2" :value="itemList.orderAddress" disabled />
      <van-field label="企业联系人" label-width="90" :value="itemList.trueName + '(' + itemList.userPhone + ')'" disabled />
      <van-field v-if="itemList.runnerName" label="收运联系人" label-width="90" :value="itemList.runnerName + '(' + itemList.runnerPhone + ')'" disabled />
      <van-field label="收运品类" label-width="90" :value="itemList.categoryName" disabled />
      <van-field label="给收运员留言" label-width="90" type="textarea" rows="2" :value="itemList.orderRemark" disabled />
      <h3>费用信息</h3>
      <div v-if="childrenList.unitType === 1 && queryInfo.weightFreightIncinerate">
        <van-field label="计费方式" label-width="90" value="按重量计费(焚烧)" disabled />
        <van-field label="重量" label-width="90" :value="itemList.orderTotalWeight + '(t)'" disabled />
        <van-field label="处置费" label-width="90" :value="itemList.orderTotalWeight + '(t) * ' + queryInfo.disposeFeeIncinerate + ' = ' + (itemList.orderTotalWeight * queryInfo.disposeFeeIncinerate).toFixed(2)" disabled />
        <van-field label="运费" label-width="90" :value="itemList.orderTotalWeight + '(t)' + ' * ' + queryInfo.weightFreightIncinerate + ' = ' + (itemList.orderTotalWeight * queryInfo.weightFreightIncinerate).toFixed(2)" disabled />
        <van-field label="合计" label-width="90" :value="itemList.orderTotalPrice" disabled />
      </div>
      <div v-if="childrenList.unitType === 2 && queryInfo.volumeFreightIncinerate">
        <van-field label="计费方式" label-width="90" value="按体积计费(焚烧)" disabled />
        <van-field label="重量" label-width="90" :value="itemList.orderTotalWeight + '(t)'" disabled />
        <van-field label="体积" label-width="90" :value="childrenList.volumeValue + '(m³)'" disabled />
        <van-field label="处置费" label-width="90" :value="itemList.orderTotalWeight + '(t) * ' + queryInfo.disposeFeeIncinerate + ' = ' + (itemList.orderTotalWeight * queryInfo.disposeFeeIncinerate).toFixed(2)" disabled />
        <van-field label="运费" label-width="90" :value="childrenList.volumeValue + '(m³)' + ' * ' + queryInfo.volumeFreightIncinerate + ' = ' + (childrenList.volumeValue * queryInfo.volumeFreightIncinerate).toFixed(2)" disabled />
        <van-field label="合计" label-width="90" :value="itemList.orderTotalPrice" disabled />
      </div>
      <div v-if="childrenList.unitType === 3 && queryInfo.weightFreightLandfill">
        <van-field label="计费方式" label-width="90" value="按重量计费(填埋)" disabled />
        <van-field label="重量" label-width="90" :value="itemList.orderTotalWeight + '(t)'" disabled />
        <van-field label="处置费" label-width="90" :value="itemList.orderTotalWeight + '(t) * ' + queryInfo.disposeFeeLandfill + ' = ' + (itemList.orderTotalWeight * queryInfo.disposeFeeLandfill).toFixed(2)" disabled />
        <van-field label="运费" label-width="90" :value="itemList.orderTotalWeight + '(t)' + ' * ' + queryInfo.weightFreightLandfill + ' = ' + (itemList.orderTotalWeight * queryInfo.weightFreightLandfill).toFixed(2)" disabled />
        <van-field label="合计" label-width="90" :value="itemList.orderTotalPrice" disabled />
      </div>
      <div v-if="childrenList.unitType === 5">
        <van-field label="合计" label-width="90" :value="itemList.orderTotalPrice" disabled />
      </div>
      <div class="tab">
        <div class="add">
          <h3>收运明细</h3>
        </div>
        <table border="1">
          <tr>
            <th>固废</th>
            <th>重量</th>
            <th v-if="childrenList.volumeValue">体积</th>
          </tr>
          <tr v-for="(item, index) in itemList.list" :key="index">
            <td>{{item.categoryName}}</td>
            <td>{{item.weightValue ? item.weightValue + item.unit : item.weightRange}}</td>
            <td v-if="item.volumeValue">{{item.volumeValue + 'm³'}}</td>
          </tr>
        </table>
        <div class="btn">
          <van-button round block type="info" @click="goBackClick">返回</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { detailsorder, querymoreInfo } from '@/api/order.js'
export default {
  data () {
    return {
      itemList: [],
      childrenList: [],
      totalPrice: undefined,
      queryInfo: undefined,
    }
  },
  created() {
    this.detailsorder()
  },
  methods: {
    detailsorder() { // 获取订单详情
      const id = this.$route.query.id
      detailsorder(id).then(res => {
        if (res.data.code === 200) {
          this.itemList = res.data.data
          this.childrenList = res.data.data.list[0]  
          this.totalPrice = this.itemList.list.map(item => item.totalPrice).reduce((a, b) => a + b)
          this.querymoreInfo()
        }
      })
    },
    querymoreInfo() { // 企业基本资料 查看 处置费
      querymoreInfo().then(res => {
        if (res.data.code === 200) {
          this.queryInfo = res.data.data.info
        }
      })
    },
    goBackClick() {
      this.$router.back()
    }
  }
}

</script>
<style lang="scss" scoped>
.warp {
  padding-bottom: 0;
  background-color: #fff;
  .main {
    h3 {
      margin: 10px 15px;
    }
    p {
      line-height: 23px;
      color: #999;
      span {
        color: #333;
      }
    }
    table {
      width: 100%;
      tr {
        th, td {
          text-align: center;
          line-height: 30px;
        }
        th {
          font-weight: 600;
        }
      }
    }
    .tab {
      padding: 0 15px;
      border-top: 1px solid #ebedf0;
      .add {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        h3 {
          margin: 10px 0;
        }
      }
    }
    h4 {
      line-height: 40px;
    }
    .beizhu {
      p {
        font-size: 12px;
        line-height: 20px;
        color: #333;
      }
    }
  }
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
::v-deep .van-cell {
  padding-top: 0;
  padding-bottom: 0;
}
::v-deep .van-cell::after {
  border: none;
}
::v-deep .van-field__control:disabled {
  color: #333;
  -webkit-text-fill-color: #333;
}
</style>
